progress {
    color:#646B72;
  }
  
  progress::-moz-progress-bar {
    background:#646B72;
  }
  
  progress::-webkit-progress-value {
    background:linear-gradient(to right, #008280 75%, #82FFFD);
  }
  
  progress::-webkit-progress-bar {
    background:#646B72;
  }