.viewport-wrapper {
  width: 100%;
  height: 100%; /* MUST have `height` to prevent resize infinite loop */
  position: relative;
}

.cornerstone-viewport-element {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;

  /* Prevent the blue outline in Chrome when a viewport is selected */
  outline: 0 !important;

  /* Prevents the entire page from getting larger
         when the magnify tool is near the sides/corners of the page */
  overflow: hidden;
}
