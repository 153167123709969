@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./tooltip.css");
@import url("./cinePlayerCustomInputRange.css");
@import url("./audioPlayerInputRange.css");
@import url("./toolbar.css");
@import url("./ImageScrollbar.css");
@import url("./OHIFCornerstoneViewport.css");
@import url("./ViewportOrientationMarkers.css");
@import url("./ViewportOverlay.css");
@import url("./lexical.css");
@import url("./progress.css");
@import url("./terms-of-use.css");
@import url("./adaptable-ai.css");
@import url("./slider.css");

@layer components {
  #__next {
    @apply h-full;
  }
}

/* TODO : override the global overflow class */

@layer utilities {
  .scrollbar {
    overflow-y: overlay;
  }

  .scrollbar::-webkit-scrollbar {
    width: 10px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gray-600;
    border: solid 3px transparent;
    background-clip: padding-box;
    border-radius: 999px;
  }

  .scrollbar-light {
    overflow-y: overlay;
  }
  .scrollbar-light::-webkit-scrollbar {
    width: 10px;
  }

  .scrollbar-light::-webkit-scrollbar-thumb {
    @apply bg-gray-400;
    border: solid 3px transparent;
    background-clip: padding-box;
    border-radius: 999px;
  }

  .scrollbar-light-x {
    overflow-x: overlay;
  }
  .scrollbar-light-x::-webkit-scrollbar {
    width: 10px;
  }

  .scrollbar-light-x::-webkit-scrollbar-thumb {
    @apply bg-gray-400;
    border: solid 6px transparent;
    background-clip: padding-box;
    border-radius: 999px;
  }

  .scrollbar-group::-webkit-scrollbar {
    width: 20px;
  }
}

.Toastify__toast {
  min-height: max-content;
  padding: 0px 8px;
  width: max-content;
}

.Toastify__toast-icon {
  width: unset !important;
}

.Toastify__toast-body {
  color: black;
  font-weight: 300;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #B4FFDD;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #FFF0D8;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #fbb3ab;
}

.Toastify__close-button {
  margin: auto;
  color: black;
}

.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 120px !important;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

:focus {
  box-shadow: none !important;
}

.layout-selector-background {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)),
    #0c1723;
}

/* TODO: Find bette rlogic for this instead of hardcoded height */
.viewport-pane {
  height: calc(100vh - 500px);
}

/* TODO: remove this global CSS and achieve this in tailwind for next/image component */
.image-open > span {
  inset: 15px !important;
}

.lexical-preview h1 {
  font-size: 2rem;
  font-weight: bolder;
}

.lexical-preview h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-report td {
  background-color: transparent !important;
  border-color: white !important;
  padding-left: 4px;
  padding-right: 4px;
}

.text-report th {
  background-color: transparent !important;
  border-color: white !important;
  padding-left: 4px;
  padding-right: 4px;
}

.editor-input td,
th {
  width: auto !important;
}

.table-responsive {
  display: block;
  overflow: auto;
}

@layer base {
  .emails-input input {
    @apply !bg-transparent;
  }

  /* to hide arrows on number inpput fields */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-Thin.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-ThinItalic.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-ExtraLight.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-ExtraLightItalic.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-Light.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-LightItalic.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-Regular.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-Italic.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-Medium.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-MediumItalic.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-SemiBold.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-SemiBoldItalic.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-Bold.ttf) format("truetype");
  }

  @font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(../fonts/IBMPlexSans-BoldItalic.ttf) format("truetype");
  }
}
