.terms-of-use h1 {
  font-size: 2em;
  line-height: 60px;
}

.terms-of-use h2 {
  font-size: 1.5em;
  font-weight: 400;
}

.terms-of-use p {
  margin-block: 20px
}

.terms-of-use ol {
  list-style: decimal;
}

.terms-of-use ol, .terms-of-use ul {
  margin-left: 10px;
  white-space: normal;
}

.terms-of-use ul {
  list-style: circle;
}

.terms-of-use li {
  padding-block: 5px;
}