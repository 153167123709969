.toolbar-scroll::-webkit-scrollbar {
  display: none;
}

.toolbar-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.right-overlay {
  background: linear-gradient(270deg, #070d13 0%, rgba(12, 23, 35, 0) 100%);

  height: 40px;
}

.left-overlay {
  background: linear-gradient(270deg, #070d13 0%, rgba(12, 23, 35, 0) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);

  height: 40px;
}
