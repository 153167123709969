.new-nodule-indicator-triangle {
    width: 0;
    height: 0;
    position: absolute;
    right: -8px;
    top: -10px;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #008280;
    transform: rotate(-45deg);
}
