.custom-vertical-slider {
  height: 4px;
  width: 100px;
  border-radius: 80px;
  position: absolute;
  transform-origin: left;
  appearance: none;
  transform: rotate(90deg);
  background-color: transparent;
  /* transform: translate(0, 0) rotate(-90deg) skewX(0) skewY(0) scaleX(1) scaleY(1); */
  /* -webkit-appearance: slider-vertical; */
}

.custom-vertical-slider::-webkit-slider-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  /* margin-top: -6px; */
  border-radius: 100%;

  background-color: #00A9A7;
}

.custom-vertical-slider::-webkit-slider-runnable-track {
  /* background-color: #646B72; */
  /* height: 4px; */
  /* border-radius: 20px; */
  /* appearance: none; */
  /* appearance: none;
  background-color: beige;
  /* height: 4px; */
}

.custom-vertical-slider::-webkit-progress-value {
  background-color: #00A9A7;
}
