.imageViewerViewport.empty ~ .viewport-overlay {
  display: none;
}
.viewport-overlay {
  color: #00d0cd;
}
.viewport-overlay .overlay-element {
  position: absolute;
  font-weight: 400;
  text-shadow: 1px 1px #000;
  pointer-events: none;
}
.top-viewport {
  top: 20px;
}

.left-viewport {
  left: 20px;
}

.viewport-overlay .top-center {
  top: 20px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
.right-viewport {
  right: 20px;
}
.bottom-viewport {
  bottom: 20px;
}

.left-viewport {
  left: 20px;
}
.viewport-overlay .bottom-right {
  bottom: 20px;
  right: 20px;
  text-align: right;
}
.viewport-overlay.controlsVisible .topright,
.viewport-overlay.controlsVisible .bottomright {
  right: calc(20px + 19px);
}
.viewport-overlay svg {
  background-color: transparent;
  margin: 2px;
  width: 18px;
  height: 18px;
}
